<template>
  <v-combobox
    v-bind="$attrs"
    v-model="selectedPrograms"
    item-text="name"
    item-value="id"
    :hide-no-data="!search"
    :search-input.sync="search"
    :items="programs"
    @change="search = ''"
  >
    <template v-slot:item="{ index, item }">
      <strong class="mr-1">{{ `${item.name}` }}</strong>
      <i>{{
        `(${item.week_count} Weeks - ${
          item.workout_type === 1 ? 'Venue' : 'Training'
        })`
      }}</i>
    </template>
  </v-combobox>
</template>

<script>
import Program from '@/models/Program'

export default {
  props: {
    value: {
      type: [Array],
    },
  },
  data() {
    return {
      selectedPrograms: [],
      programs: [],
      search: '',
    }
  },

  async created() {
    const { data } = await Program.get()
    this.programs = data

    this.selectedPrograms = this.value
  },

  watch: {
    value(value) {
      this.selectedPrograms = value
    },
    selectedPrograms(value) {
      this.$emit('input', value)
    },
  },
}
</script>
