<template>
  <v-row class="bbr-import-recipe">
    <v-col cols="12">
      <v-form v-model="valid_file">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-file-input
                v-model="form.file"
                :rules="[(v) => !!v || 'File is required']"
                accept=".xls,.xlsx"
                label="Import workouts"
                :disabled="ingestion_ready"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <multi-trainer-search-input
                label="Workout Trainers"
                :error-messages="form.$getError('trainer_ids')"
                v-model="form.trainers"
                :disabled="ingestion_ready"
                hide-selected
                small-chips
                multiple
                outlined
                flat
                deletable-chips
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model="form.extra"
                class="d-inline-flex mr-4"
                label="Extra"
                :disabled="ingestion_ready"
              ></v-switch>

              <v-switch
                v-model="form.extra_difficulty"
                class="d-inline-flex"
                label="Extra Difficulty"
                :disabled="ingestion_ready"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <multi-program-search-input
                label="Programs To Map"
                :error-messages="form.$getError('program_ids')"
                v-model="form.programs"
                :disabled="
                  ingestion_ready || form.extra || form.extra_difficulty
                "
                hide-selected
                small-chips
                multiple
                outlined
                flat
                deletable-chips
              />
            </v-col>
          </v-row>

          <v-row v-if="!validation_errors.length && validation_summary">
            <v-col>
              <h3>Import Validation Summary:</h3>

              <h4 class="mt-5" v-if="validation_summary.programs">
                Programs to map:
              </h4>

              <v-card class="mx-auto mt-5" tile>
                <v-list-item
                  v-for="(program, index) in validation_summary.programs"
                  :key="index"
                >
                  <v-list-item-content>
                    <span>
                      <strong>{{ program.name }}</strong>
                      <v-chip class="ma-1" color="primary" small label>
                        {{ program.venue }} </v-chip
                      ><v-chip
                        v-if="!program.completed_at"
                        class="ma-1"
                        color="primary lighten-1"
                        small
                        label
                      >
                        Draft
                      </v-chip>
                      &mdash; {{ program.week_count }} week(s)
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

              <h4 class="mt-5">Sheets:</h4>

              <v-expansion-panels multiple class="mt-5">
                <v-expansion-panel
                  v-for="(sheet, index) in validSheets"
                  :key="index"
                >
                  <v-expansion-panel-header
                    ><span
                      ><strong>{{ sheet.sheet }}</strong>
                      <v-chip
                        class="ma-1"
                        color="primary"
                        small
                        label
                        v-if="sheet.workout_type"
                      >
                        {{ sheet.workout_type.description }}
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        color="primary lighten-1"
                        small
                        label
                        v-if="sheet.training_program"
                      >
                        {{ sheet.training_program }}
                      </v-chip>
                      <span v-if="sheet.weeks.length"
                        >&mdash; {{ sheet.weeks.length }} week(s)</span
                      >
                      &mdash; {{ sheet.workouts.length }} workout(s)
                    </span></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-expansion-panels multiple>
                      <v-expansion-panel
                        v-for="workout in sheet.workouts"
                        :key="workout.row"
                      >
                        <v-expansion-panel-header
                          ><span
                            >Workout <strong>{{ workout.workout }}</strong>
                            <span v-if="workout.tags && workout.tags.length">
                              <v-chip
                                v-for="(tag, index) in workout.tags"
                                :key="index"
                                class="ma-1"
                                color="primary lighten-1"
                                label
                                small
                                text-color="white"
                              >
                                <v-icon left x-small>
                                  {{ icons.tag }}
                                </v-icon>
                                {{ tag }}
                              </v-chip>
                            </span></span
                          ></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <div
                            v-for="(circuit, key) in workout.circuits"
                            :key="key"
                          >
                            <div
                              v-for="(exercise, key) in circuit.exercises"
                              :key="key"
                            >
                              Circuit <strong>{{ circuit.name }}</strong
                              >, Order
                              <strong> {{ exercise.order }}</strong> &mdash;
                              Exercise
                              <strong>{{ exercise.exercise }}</strong>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row v-if="validation_warnings.length || validation_errors.length">
            <v-col>
              <v-simple-table
                v-if="validation_errors.length > 0"
                class="red lighten-4 mt-5"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Validation Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(error, i) in validation_errors" :key="i">
                      <td>{{ error }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                v-if="validation_warnings.length > 0"
                class="yellow lighten-4 mt-5"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Warnings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in validation_warnings" :key="i">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          v-if="ingestion_ready"
          class="mr-4"
          @click="importFile(true)"
          :loading="loading"
        >
          Ingest
        </v-btn>
        <v-btn v-else class="mr-4" @click="validateFile" :loading="loading">
          Validate
        </v-btn>
        <confirm-dialog ref="confirm" @confirmed="confirmed" />
      </v-form>
    </v-col>
    <v-toast ref="toast" />
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import { values, flattenDeep, map } from 'lodash'
import VToast from '@/components/elements/Toast'
import Form from '@/utils/form'
import MultiProgramSearchInput from '@/components/elements/MultiProgramSearchInput'
import MultiTrainerSearchInput from '@/components/elements/MultiTrainerSearchInput'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mdiTag } from '@mdi/js'

export default {
  name: 'ImportRecipe',

  components: {
    VToast,
    MultiProgramSearchInput,
    MultiTrainerSearchInput,
    ConfirmDialog,
  },

  data() {
    return {
      icons: {
        tag: mdiTag,
      },
      loading: false,
      valid_file: null,
      ingestion_ready: false,
      validation_error_message: null,
      validation_summary: null,
      validation_errors: [],
      validation_warnings: [],
      form: new Form({
        file: null,
        trainers: [],
        programs: [],
        extra: false,
        extra_difficulty: false,
        ingest: false,
      }),
    }
  },

  computed: {
    validSheets() {
      return this.validation_summary?.sheets?.filter(
        (s) => s.training_program && s.is_valid
      )
    },

    hasActivePrograms() {
      return this.validation_summary?.programs?.find((p) => p.completed_at)
    },
  },

  methods: {
    ...mapActions({
      bulkImport: 'workouts/bulkImport',
      bulkValidate: 'workouts/bulkValidate',
    }),

    confirmed(confirmed) {
      this.ignoreDraftWarning = confirmed
    },

    getValidationSummaryErrors(data) {
      return [
        ...(data.errors ?? []),
        ...(data.programs
          ? data.programs.filter((p) => p.errors?.length).map((p) => p.errors)
          : []),
        ...data.sheets.filter((s) => s.errors?.length).map((s) => s.errors),
      ].flat()
    },

    getValidationSummaryWarnings(data) {
      return [
        ...(data.warnings ?? []),
        ...(data.programs
          ? data.programs
              .filter((p) => p.warnings?.length)
              .map((p) => p.warnings)
          : []),
        ...data.sheets.filter((s) => s.warnings?.length).map((s) => s.warnings),
      ].flat()
    },

    clearValidationResults() {
      this.validation_errors = []
      this.validation_warnings = []
      this.validation_summary = null
      this.validation_error_message = null
    },

    validateFile() {
      if (this.form.file === null) return

      this.form.$clearErrors()

      let payload = new FormData()

      payload.append('excelFile', this.form.file)
      payload.append('program_ids', map(this.form.programs, 'id'))
      payload.append('trainer_ids', map(this.form.trainers, 'id'))
      payload.append('extra', this.form.extra)
      payload.append('extra_difficulty', this.form.extra_difficulty)
      payload.append('ingest', this.form.ingest)

      this.$refs.toast.open({
        color: 'primary',
        message: 'Import workouts validating...',
      })

      this.clearValidationResults()

      // Create record
      this.loading = true

      this.bulkValidate(payload)
        .then((response) => {
          this.loading = false

          this.validation_summary = response.data.data

          this.validation_errors = this.getValidationSummaryErrors(
            this.validation_summary
          )

          this.validation_warnings = this.getValidationSummaryWarnings(
            this.validation_summary
          )

          this.ingestion_ready = !this.validation_errors.length
        })
        .catch((err) => {
          this.loading = false
          if (err) {
            if (err.response?.data !== undefined) {
              if (err.response.data?.errors)
                this.form.$timeout(() => {
                  this.form.$setErrors(err.response.data.errors)
                  this.form.$busy = false
                })
              this.validation_errors = err.response.data.errors
              this.$refs.toast.open({
                color: 'red',
                timeout: 3000,
                message:
                  (err.response.data?.errors &&
                    flattenDeep(values(err.response.data.errors)).join(' ')) ||
                  'Error processing',
              })
            }
          }
        })
    },
    async importFile() {
      if (this.form.file === null) return

      if (this.hasActivePrograms) {
        const confirm = await this.$refs.confirm.open(
          'Active Program Found',
          'Are you sure you want to proceed ingesting on an active program?'
        )
        if (!confirm) return
      }

      let payload = new FormData()

      payload.append('excelFile', this.form.file)
      payload.append('program_ids', map(this.form.programs, 'id'))
      payload.append('trainer_ids', map(this.form.trainers, 'id'))
      payload.append('extra', this.form.extra)
      payload.append('extra_difficulty', this.form.extra_difficulty)
      payload.append('ingest', this.form.ingest)

      // Create record
      this.loading = true

      this.bulkImport(payload)
        .then((response) => {
          this.loading = false

          if (response.data.success) {
            this.ingestion_ready = false
            this.clearValidationResults()
          }

          this.$refs.toast.open({
            color: 'primary',
            message: response.data.message,
          })
        })
        .catch((err) => {
          this.loading = false
          if (err) {
            if (err.response.data !== undefined) {
              if (err.response.data?.errors)
                this.form.$timeout(() => {
                  this.form.$setErrors(err.response.data.errors)
                  this.form.$busy = false
                })
              this.validation_error_message = err.response.data.message
              this.$refs.toast.open({
                color: 'red',
                timeout: 300000,
                message:
                  (err.response.data?.errors &&
                    flattenDeep(values(err.response.data.errors)).join(' ')) ||
                  'Error processing',
              })
            }
          }
        })
    },
  },

  watch: {
    'form.extra'(value) {
      if (value) this.form.programs = []
    },

    'form.extra_difficulty'(value) {
      if (value) {
        this.form.extra = true
        this.form.programs = []
      }
    },
  },
}
</script>
