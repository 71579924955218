<template>
  <v-combobox
    v-bind="$attrs"
    v-model="selectedTrainers"
    item-text="name"
    item-value="id"
    :hide-no-data="!search"
    :search-input.sync="search"
    :items="trainers"
    @change="search = ''"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-text="data.item.name" />
        <v-list-item-subtitle class="text-no-wrap" style="width:20px">
          {{ data.item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
import Trainer from '@/models/Trainer'

export default {
  props: {
    value: {
      type: [Array],
    },
  },
  data() {
    return {
      selectedTrainers: [],
      trainers: [],
      search: '',
    }
  },

  async created() {
    const { data } = await Trainer.get()
    this.trainers = data

    this.selectedTrainers = this.value
  },

  watch: {
    value(value) {
      this.selectedTrainers = value
    },
    selectedTrainers(value) {
      this.$emit('input', value)
    },
  },
}
</script>
